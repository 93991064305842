<template>
    <div class="cart-order">
        <div class="catalog-notification">
            <CartNotification :messages="messages"/>
        </div>
        <div class="cart-order__wrapper">
            <div class="cart-order__inner">
                <button class="cart-order__close" @click="closeCart"></button>
                <h1 class="cart-order__heading">Оформление заказа</h1>

                <div class="cart-order__content">
                    <div class="cart-order__article cart-article">
                        <h2 class="cart-article__subheading cart-subheading">Данные заказчика</h2>
                        <form class="cart-form">
                            <input 
                                type="text"
                                placeholder="ФИО"
                                class="cart-form__input"
                                v-model="state.name"
                                :class="{invalid: v$.name.$error}">
                            <input 
                                type="tel" 
                                placeholder="+7(___)___-__-__"
                                class="cart-form__input"
                                v-model="state.tel"
                                v-imask="phoneNumberMask" 
                                maxlength="16"
                                :class="{invalid: v$.tel.$error}">
                            <input 
                                type="text"
                                placeholder="E-mail"
                                class="cart-form__input"
                                v-model="state.email"
                                :class="{invalid: v$.email.$error}">
                        </form>
                        <div class="cart-article__products">
                            <h2 class="cart-article__subheading cart-subheading">Ваши товары</h2>
                            <CartItem
                                v-for="(item, index) in CART"
                                :key="item.id"
                                :cart_item_data="item"
                                @deleteFromCart="deleteFromCart(index)"
                                @increase="increase(index)"
                                @decrease="decrease(index)"/>

                            <div class="cart-article__discount discount-form">
                                <input 
                                    type="text" 
                                    placeholder="Промокод"
                                    v-model="promo"
                                    class="discount-form__input input">

                                <button
                                    @click="submitPromo" 
                                    type="button" 
                                    class="discount-form__btn">{{promoBtn}}</button>
                            </div>

                            <div class="cart-article__total">
                                <p>Сумма заказа: <span class="cart-article__total-price">{{ cartTotalCost }}</span> р.</p>

                                <p class="cart-article__discount" v-if="DISCOUNT != 0">Со скидкой: <span class="cart-article__total-price">{{ costWithDiscount }}</span> р.</p>
                            </div> 
                        </div>
                    </div>
                    <!-- 2 колонка -->
                    <div class="cart-order__aside cart-aside">
                        <h2 class="cart-aside__subheading cart-subheading">Способ доставки</h2>
                        <form class="aside-form cart-order__form">
                            <div class="aside-form__controls">
                                <!-- id="pickup" -->
                                <input 
                                    type="radio" 
                                    id="pickup"
                                    name="delivery" 
                                    class="aside-form__radio"
                                    @change="selectDelivery(true)"
                                    ref="selfPickupRadio"
                                    >
                                <label
                                    :class="{unavailable: !isSelfAvailable}"  
                                    class="aside-form__label aside-form__label_self"
                                    for="pickup">{{selfPickupText}}</label>
                            </div>
                            <div class="aside-form__controls">
                                <input 
                                    id="courier" 
                                    type="radio" 
                                    name="delivery" 
                                    class="aside-form__radio"
                                    @change="selectDelivery(false)"
                                    ref="deliveryRadio"
                                    >

                                <label 
                                    for="courier" 
                                    class="aside-form__label"
                                    :class="{unavailable: !isDeliveryAvailable}" >{{deliveryAvailableText}}</label>
                                <span class="aside-form__notification">*доставка будет осуществлена по тарифам такси</span>
                            </div>
                            <!-- Курьер -->
                            <div v-if="!isSelfPickup" class="aside-form__courier form-courier">
                                <p class="form-courier__heading form-input-heading">Доставка курьером</p>
                                <div class="form-courier__inputs">

                                    <div class="form-courier__location">
                                        <input 
                                        class="form-courier__input form-courier__input-city form-input" 
                                        placeholder="Город"
                                        v-model="state.city">
                                        <input 
                                        class="form-courier__input form-courier__input-street form-input" 
                                        placeholder="Улица"
                                        v-model="state.street">
                                    </div>

                                    <div class="form-courier__house">
                                        <input 
                                        class="form-courier__input form-courier__input-house form-input" 
                                        placeholder="Дом"
                                        v-model="state.home">

                                        <input 
                                        class="form-courier__input form-courier__input-flat form-input" 
                                        placeholder="Квартира"
                                        v-model="state.flat">

                                        <input 
                                        class="form-courier__input form-courier__input-floor form-input" 
                                        placeholder="Этаж"
                                        v-model="state.floor">
                                    </div>

                                    <textarea 
                                        rows="5"
                                         placeholder="Комментарий к заказу" class="form-courier__textarea form-input" 
                                         v-model="state.comment"></textarea>
                                </div>
                                <div class="cart-form__checkboxes">
                                    <span 
                                        class="cart-form__checkbox"
                                        :class="{checkbox_select: isAnotherRecipient}"
                                        @click="selectRecipient"></span>
                                    <input 
                                        id="another" 
                                        type="checkbox" 
                                        class="checkbox">

                                    <label 
                                        for="another" 
                                        class="cart-form__label"
                                        @click="selectRecipient">Получатель другой человек</label>
                                </div>
                                <div class="form-recipient" v-if="isAnotherRecipient">

                                    <input type="text" 
                                        class="form-recipient__input form-input" 
                                        placeholder="Имя"
                                        v-model="state.recipientName">

                                    <input type="tel" 
                                        class="form-recipient__input form-input " 
                                        placeholder="+7(___)___-__-__"
                                        v-model="state.recipientTel"
                                        v-imask="phoneNumberMask" 
                                        maxlength="16"
                                        :class="{invalid: v$.recipientTel.$error}"
                                    >
                                </div>

                                <p class="cart-form__input-heading form-input-heading">Дата и время доставки</p>
                                <input 
                                    type="datetime-local" 
                                    class="aside-form__datetime" 
                                    v-model="state.date">
                                <div class="cart-form__checkboxes">
                                    <span 
                                        class="cart-form__checkbox"
                                        :class="{checkbox_select: isWithoutCall}"
                                        @click="selectCall"></span>
                                    <input 
                                        id="call" 
                                        type="checkbox" 
                                        class="checkbox">

                                    <label 
                                        for="call" 
                                        class="cart-form__label"
                                        @click="selectCall">Доставить без звонка получателю</label>
                                </div>
                                <div class="cart-form__checkboxes">
                                    <span 
                                        class="cart-form__checkbox"
                                        :class="{checkbox_select: isNotify}"
                                        @click="selectNotification"></span>
                                    <input 
                                        id="notification" 
                                        type="checkbox" 
                                        class="checkbox">

                                    <label 
                                        for="notification" 
                                        class="cart-form__label"
                                        @click="selectNotification">Уведомить меня по телефону после доставки</label>
                                </div>
                            </div>
                            <!-- Самовывоз -->
                            <div v-if="isSelfPickup">
                                <h3 class="aside-form__subheading">Выберите магазин</h3>
                                <div 
                                    class="aside-form__controls"
                                    v-for="address in addresses"
                                    :key="address.id">

                                    <input 
                                        :id="address.id" 
                                        type="radio" 
                                        name="shop-address" 
                                        class="aside-form__radio">

                                    <label 
                                        :for="address.id" 
                                        class="aside-form__label"
                                        @click="selectAddress(address.address)">{{address.address}}
                                    </label>
                                </div>
    
                                <div class="aside-form__date">
                                    <div class="aside-form__date-pickup">
                                        <p class="aside-form__date-heading">Дата</p>
                                        <input 
                                            type="date" 
                                            class="aside-form__datetime"
                                            v-model="pickupDate">
                                    </div>
                                    <div class="aside-form__time-pickup">
                                        <p class="aside-form__date-heading">Время готовности</p>
                                        <input 
                                            type="time" 
                                            class="aside-form__datetime"
                                            v-model="pickupTime">
                                    </div>
                                </div>
                            </div>
                            <div class="cart-form__checkboxes">
                                <span 
                                    class="cart-form__checkbox"
                                    :class="{checkbox_select: isNeedCard}"
                                    @click="selectCard"></span>
                                <input 
                                    id="radio__another" 
                                    type="checkbox" 
                                    class="checkbox">

                                <label 
                                    for="radio__another" 
                                    class="cart-form__label"
                                    @click="selectCard">Добавить открытку</label>
                            </div>
                            <textarea
                                v-if="isNeedCard" 
                                rows="5" 
                                class="aside-form__textarea"
                                placeholder="Текст открытки"
                                v-model="state.card"></textarea>
                        </form>
                    </div>
                </div>

                <div class="cart-order__additional additional" v-if="ADDITIONAL_PRODUCTS.length !=0">
                    <h1 class="cart-order__heading additional__heading">Дополнительные товары</h1>
                    <div class="additional__inner">
                        <CatalogItem
                            class="additional__item"
                            v-for="flower in ADDITIONAL_PRODUCTS"
                            @addToCart="addToCart" 
                            :key="flower.id"
                            :product_data="flower"/>
                    </div>
                </div>

                <p class="cart-order__notification">*Доставка курьером платная. Осуществляется по тарифам такси.</p>
                <!-- <div class="cart-order__sbp" v-if="!isSelfPickup">
                    <span 
                        class="cart-form__checkbox"
                        :class="{checkbox_select: isSBP}" 
                        @click="selectSBP"></span>
                    <input 
                        id="sbp" 
                        type="checkbox" 
                        class="checkbox">

                    <label 
                        for="sbp" 
                        class="cart-form__label"
                        @click="selectSBP">Оплатить заказ через СБП</label>
                </div> -->
                <button 
                    class="cart-order__submit" 
                    @click="submit"
                    :class="{loading: isLoading}">{{textOrderBtn}}</button>

                <p class="cart-order__policy">Нажимая кнопку "оформить заказ", Вы даете согласие на сбор и обработку персональных данный, согласно <span class="cart-order__policy-link" @click="closeAndPush"> политике конфиденциальности</span ></p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CartItem from './Cart-item'
import CatalogItem from '@/components/Catalog-item'
import CartNotification from '@/components/Cart-notification';
import {mapGetters, mapActions} from 'vuex'
import useValidate from '@vuelidate/core'
import { required, email, minLength} from '@vuelidate/validators'
import { reactive } from 'vue'
import { IMaskDirective } from 'vue-imask';

export default {
    setup(){
        const state = reactive({
            name: '',
            tel: '',
            email: '',
            city: '',
            street: '',
            home: '',
            flat: '',
            floor: '',
            comment: '',
            recipientName: '',
            recipientTel: '',
            card: '',
            date: '',
        })
        const rules = {
            name: {required},
            tel: {required, minLength: minLength(16)},
            recipientTel: {minLength: minLength(16)},
            email: {required, email}
        }
        const v$ = useValidate(rules, state)
        return {state, v$}
    },
    data() {
        return {
            messages: [],
            promo: '',
            isPromoCorrect: false,
            promoName: '',
            promoPercent: 0,
            addresses: [
                {id: 1, address: 'Комендантский проспект, 67'},
                {id: 2, address: 'Ланское шоссе 20'},
                {id: 3, address: 'Московский проспект, 200'},
                {id: 4, address: 'Пражская улица, 3'},
                {id: 5, address: 'Богатырский проспект, 49, к. 1'},
                {id: 6, address: 'Московский проспект, 73'},
                {id: 7, address: 'Заставская улица, 22С (Оптовый склад)'},
                {id: 8, address: 'Караваевская улица, 25, к. 1'},
                {id: 9, address: 'ул. Оптиков, 37 (вход со стороны улицы)'},
                {id: 10, address: 'Европейский проспект, 11'},
                {id: 11, address: 'Ленинский проспект 72, к. 1'},
                {id: 12, address: 'Дальневосточный проспект 12, к. 2'},
            ],
            isSelfPickup: false,
            isAnotherRecipient: false,
            isWithoutCall: false,
            isNotify: false,
            isNeedCard: false,
            isSBP: false,
            pickupDate: '',
            pickupTime: '',
            selectedAddress: '',
            isDeliveryAvailable: '',
            isSelfAvailable: '',
            isAdmin: false,
            phoneNumberMask: {
                mask: '+{7}(000)000-00-00',
                lazy: true
            },
            isLoading: false 
        }
    },
    components: {
        CartItem,
        CatalogItem,
        CartNotification
    },
    computed:{
        ...mapGetters([
            'CART',
            'ADDITIONAL_PRODUCTS',
            'DISCOUNT'
        ]),
        cartTotalCost(){
            let result = [0]
            for (let item of this.CART){
                result.push(item.price * item.quantity)
            }
            result = result.reduce(function (sum, el){
                return sum + el;
            })
            return result;
        },
        costWithDiscount(){
            let result = [0]
            for (let item of this.CART){
                result.push(item.price * item.quantity)
            }
            result = result.reduce(function (sum, el){
                return sum + el;
            })

            return result - result * this.DISCOUNT / 100;
        },
        description(){
            let descArr = []
            let descStr = ''
            for(let e of this.CART){
                descArr.push('Название: ' + e.name)
                descArr.push('Кол-во: ' + e.quantity)
            }
            descStr = descArr.join(', ')
            return descStr;
        },
        textOrderBtn(){
            return this.isLoading ? 'Загрузка...' : 'Оформить заказ'
        },
        promoBtn(){
            return this.isPromoCorrect ? 'Промокод применен!' : 'Применить'
        },
        deliveryAvailableText(){
            return this.isDeliveryAvailable ? 'Доставка курьером' : '(временно недоступно)'
        },
        selfPickupText(){
            return this.isSelfAvailable ? 'Самовывоз' : 'Самовывоз временно недоступен'
        },
    },
    methods: {
        ...mapActions([
            'DELETE_FROM_CART',
            'INCREASE_QTY',
            'DECREASE_QTY',
            'GET_ADDITIONAL_PRODUCTS',
            'IS_DISCOUNT'
        ]),
         submitPromo(){
            if (this.promo== '') {
                alert('Введите промокод')
            }else{
                axios({
                    method: 'GET',
                    url: 'https://dff24.com:3000/api/promo/check',
                    params: {
                        name: this.promo
                    }
                }).then((res)=>{
                    if (res.data.message == 'Промокод не найден') {
                        alert('Промокод не найден')
                    }else if(res.data.message == 'Промокод не активен'){
                        alert('Промокод не активен')
                    }else{
                        this.isPromoCorrect = true
                        this.promoName = res.data.name
                        this.promoPercent = res.data.percent
                        this.IS_DISCOUNT(res.data.percent) 
                    }
                })
            }
        },
        deleteFromCart(index){
            this.DELETE_FROM_CART(index)
        },
        increase(index){
            this.INCREASE_QTY(index)
        },
        decrease(index){
            this.DECREASE_QTY(index)
        },
        closeCart(){
            this.$emit('closeCart', false)
        },
        closeAndPush(){
            this.$emit('closeCart', false)
            this.$router.push('/confidential')
        },
        selectDelivery(bool){
            this.isSelfPickup = bool
        },
        selectRecipient(){
            this.isAnotherRecipient = !this.isAnotherRecipient
        },
        selectCall(){
            this.isWithoutCall = !this.isWithoutCall
        },
        selectNotification(){
            this.isNotify = !this.isNotify
        },
        selectCard(){
            this.isNeedCard = !this.isNeedCard
        },
        selectAddress(address){
            this.selectedAddress = address
        },
        addToCart(){
            let timeStamp = Date.now().toLocaleString()
            this.messages.unshift(
                {name: "Товар добавлен в корзину", id: timeStamp}
            )
        },
        selectSBP(){
            this.isSBP = !this.isSBP
        },
        async submit(){
            if(this.isSelfPickup && !this.selectedAddress){
                return alert('Выберите адрес выдачи')
            }
            if (!this.CART.length){
                return alert('Корзина пуста')
            }
            const isFormCorrect = await this.v$.$validate()
            if(!isFormCorrect){
                return alert('Ошибка в форме')
            }
            this.isLoading = true;
            let totalSum = 0
            if (this.DISCOUNT != 0) {
                totalSum = this.costWithDiscount
            }else{
                totalSum = this.cartTotalCost
            }
            const res = await fetch('https://dff24.com:3000/api/orders', {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({
                    id: Math.floor(new Date().valueOf() / 1000),
                    order: this.description,
                    sumWithoutPromo: this.cartTotalCost,
                    sum: totalSum,
                    discountValue: this.promoPercent,
                    discountName: this.promoName,
                    customer: this.state.name,
                    phonenumber: this.state.tel,
                    email: this.state.email,
                    city: this.state.city,
                    street: this.state.street,
                    house: this.state.home,
                    flat: this.state.flat,
                    floor: this.state.floor,
                    comment: this.state.comment,
                    anothername: this.state.recipientName,
                    anotherphone: this.state.recipientTel,
                    cardtext: this.state.card,
                    deliverydate: this.state.date,
                    isSelfPickup: this.isSelfPickup,
                    isWithoutCall: this.isWithoutCall,
                    isNotify: this.isNotify,
                    pickupDate: this.pickupDate,
                    pickupTime: this.pickupTime,
                    selectedAddress: this.selectedAddress,
                    isSBP: this.isSBP
                })
            }).then(response=>response.text()).then((link) =>{
                window.location.href = link
            })
        },
        getDeliveryAvailable(){
            axios.get('https://dff24.com:3000/api/delivery/get').then((res)=>{
                this.isDeliveryAvailable = res.data.isDelivery
                this.isSelfAvailable = res.data.isSelfPickup
                if (res.data.isDelivery == false) {
                    this.isSelfPickup = true
                    this.$refs.selfPickupRadio.checked = true 
                }else{
                    this.$refs.deliveryRadio.checked = true
                }
            })
        }
    },
    directives: {
        imask: IMaskDirective
    },
    mounted() {
        
        this.getDeliveryAvailable()
        let role = localStorage.getItem('role')
        if (role == 'admin') {
            this.isAdmin = true
        }
        this.GET_ADDITIONAL_PRODUCTS()
    },
}
</script>

<style scoped>
    .cart-order__wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
        background-color: rgba(0, 0, 0, .5);
    }
    .cart-order__inner{
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 1270px;
        overflow-y: scroll;
        width: 100%;
        padding: 60px 100px;
        background-color: #FAF2E4;
    }
    .cart-order__content{
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }
    .cart-order__article{
        max-width: 430px;
        flex-grow: 1;
        margin-right: 40px;
    }
    .cart-order__heading{
        text-align: center;
        font-size: 40px;
        font-weight: 400;
        margin-bottom: 50px;
    }
    .cart-subheading{
        font-size: 24px;
        font-weight: 400;
    }
    .cart-article__subheading{
        margin-bottom: 30px;
    }
    .cart-form{
        display: flex;
        flex-direction: column;
    }
    .cart-article__products{
        margin-top: 50px;
    }
    .cart-form__input{
        width: 100%;
        padding: 10px 5px;
        margin-bottom: 20px;
        background-color: transparent;
        border: 1px solid #000;
    }
    .cart-form__input:last-child{
        margin-bottom: 0;
    } 
    .cart-article__total{
        margin-top: 30px;
        font-size: 26px;
    }
    .cart-article__discount{
        margin-top: 25px;
    }
    .cart-article__total-price{
        margin-left: 20px;
    }
    /* 2 колонка */
    .cart-order__aside{
        max-width: 520px;
        flex-grow: 1;
    }
    .aside-form__radio:checked + .aside-form__label::after{
        transform: scale(1);
    }
    .aside-form__label{
        font-size: 15px;
        display: inline-block;
        padding-left: 35px;
        position: relative;
        margin-top: 30px;
    }
    .aside-form__label::before{
        content: "";
        position: absolute;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #FAF2E4;
        border: 1px solid #000;
        left: 0;
        top: 2px;
    }
    .aside-form__label::after{
        content: "";
        position: absolute;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: #000;
        left: 2px;
        top: 4px;
        transition: all linear .2s;
        transform: scale(0);
    }
    .aside-form__radio{
        display: none;
    }
    .aside-form__notification{
        color: #DD3030;
        padding-left: 35px;
        display: inline-block;
        margin-top: 5px;
    }
    .aside-form__subheading{
        margin-top: 30px;
        font-size: 18px;
        font-weight: 400;
    }
    .aside-form__date{
        display: flex;
        margin-top: 40px;
    }
    .aside-form__date-pickup{
        margin-right: 40px;
    }
    .aside-form__date-pickup:last-child{
        margin-right: 0;
    }
    .aside-form__date-heading{
        margin-bottom: 15px;
    }
    .aside-form__datetime{
        height: 40px;
        width: 160px;
        background-color: transparent;
        border: 1px solid #000;
        padding-left: 5px;
    }
    .aside-form__textarea{
        width: 100%;
        display: block;
        background-color: transparent;
        padding: 10px 20px;
        margin-top: 20px;
    }
    .cart-order__submit{
        color: #fff;
        font-size: 18px;
        background-color: #000;
        padding: 20px 100px;
        text-transform: uppercase;
        align-self: center;
        cursor: pointer;
    }
    .cart-order__sbp{
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .cart-order__close{
        position: absolute;
        top: 30px;
        right: 60px;
        height: 40px;
        width: 40px;
    }
    .cart-order__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #000;
        top: 20px;
        right: 0;
        transform: rotate(45deg);
    }
    .cart-order__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #000;
        top: 20px;
        right: 0;
        transform: rotate(-45deg);
    }
    .cart-order__close:hover{
        cursor: pointer;
    }
    /* Курьер */
    .cart-form__label:hover{
        cursor: pointer;
    }
    .aside-form__courier{
        margin-top: 25px;
    }
    .form-input{
        border: 1px solid #000;
        background-color: transparent;
        outline: none;
        padding: 10px 20px;
        margin-top: 15px;
    }
    .form-courier__location{
        display: flex;
    }
    .form-courier__input-city{
        flex-basis: 50%;
    }
    .form-courier__input-street{
        flex-basis: 50%;
        margin-left: 10px;
    }
    .form-courier__house{
        display: flex;
    }
    .form-courier__input-house{
        width: 100%;
    }
    .form-courier__input-flat{
        width: 100%;
        margin-left: 8px;
    }
    .form-courier__input-floor{
        width: 100%;
        margin-left: 8px;
    }
    .form-courier__textarea{
        width: 100%;
    }
    .form-recipient__input{
        width: 100%;
    }
    .form-input-heading{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    /* чекбокс */
    .cart-form__checkboxes{
        margin-top: 30px;
    }
    .cart-form__checkbox{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-right: 15px;
        margin-bottom: -2px;
        border: 1px solid #000;
    }
    .cart-form__checkbox:hover{
        cursor: pointer;
    }
    .checkbox{
        display: none;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 17px;
        width: 2px;
        top: 0;
        right: 5px;
        transform: rotate(30deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        bottom: 1px;
        left: 4px;
        transform: rotate(-30deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .invalid{
        border-color: red;
    }
    .loading{
        pointer-events: none;
        background-color: #D8D8D8;
    }
    .cart-order__notification{
        color: #DD3030;
        text-align: center;
        margin-bottom: 25px;
        font-size: 18px;
    }
    /* Врененно недоступно */
    /* .aside-form__label_self{
        color: #adadad;
    }
    .aside-form__label_self::before{
        border-color: #adadad;
    }
    .aside-form__label_self:hover{
        pointer-events: none;
        cursor: not-allowed;
    }
    */
    .cart-order__policy{
        text-align: center;
        margin-top: 25px;
    }
    .cart-order__policy-link{
        text-decoration: underline;
    }
    .cart-order__policy-link:hover{
        cursor: pointer;
    } 
    /* Доп товары */
    .cart-order__additional{
        position: relative;
        margin-bottom: 50px;
    }
    .cart-order__additional::after{
        position: absolute;
        content: "";
        bottom: 0;
        right: -15px;
        width: 70px;
        height: calc(100% - 31px);
        background: linear-gradient(270deg, #fff, hsla(0, 0%, 100%, 0) 50%);
        display: none;
    }
    .additional__inner{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
    }
    .cart-article__discount{
        display: flex;
        margin-bottom: 60px;
    }
    .discount-form__input{
        width: 100%;
        margin-right: 27px;
        background-color: transparent;
        padding: 10px 5px;
        border: 1px solid #000;
    }
    .discount-form__btn{
        color: #fff;
        padding: 10px 35px;
        background-color: #000;
        font-weight: 400;
        transition: all linear .2s;
        font-size: 16px;
    }
    .discount-form__btn:hover{
        opacity: .5;
    }
    .unavailable{
        pointer-events: none;
        color: #707070;
    }
    .unavailable::before{
        border-color: #707070;
    }
    .unavailable::after{
        background-color: #707070;
    }
    @media (max-width: 1090px) {
        .cart-order__inner{
            padding: 30px;
        }
    }
    @media (max-width: 992px){
        .cart-order__content{
            flex-direction: column;
        }
        .cart-order__inner{
            padding: 60px;
        }
        .cart-order__article{
            margin-right: 0;
            margin-bottom: 25px;
            max-width: none;
            width: 100%;
        }
        .cart-order__aside{
            max-width: none;
        }
    }
    @media (max-width: 600px){
        .cart-order__heading{
            font-size: 26px;
            margin-bottom: 30px;
        }
        .cart-article__subheading{
            font-size: 20px;
            margin-bottom: 20px;
        }
        .aside-form__date{
            flex-direction: column;
        }
        .aside-form__datetime{
            margin-bottom: 30px;
            width: 100%;
        }
        .aside-form__date-pickup{
            margin-right: 0;
        }
        .cart-order__inner{
            padding-left: 15px;
            padding-right: 15px;
        }
        .form-courier__location{
            flex-direction: column;
        }
        .form-courier__input-street{
            margin-left: 0;
        }
        .cart-order__close{
            right: 15px;
        }
        .cart-order__submit{
            padding: 20px 80px;
        }
        .cart-order__additional::after{
            display: block;
        }
        .additional__inner{
            width: 100%;
            display: flex;
            overflow-x: scroll;
            padding-bottom: 30px;
            padding-right: 30px;
        }
        .discount-form__btn{
            padding: 10px;
        }
    }
</style>